import { useEffect, useState } from 'react'
import sizeImagePath from '~shared/utils/sizeImagePath'

const getLoadingStyle = ({ path, withPreview, loadingStyle, isVideo }) => {
  if (withPreview) {
    if (isVideo) {
      return {
        filter: 'blur(3px)',
      }
    }
    return {
      backgroundImage: `url(${sizeImagePath(path, 'xxs')})`,
      filter: 'blur(3px)',
    }
  }
  return loadingStyle
}

export const useFallbackBackground = ({
  path,
  fallbackStyle,
  loadingStyle,
  size,
  withPreview,
  isVideo,
  isPreload,
}) => {
  const [style, setStyle] = useState(
    path ? getLoadingStyle({ path, withPreview, loadingStyle, isVideo }) : { ...fallbackStyle },
  )
  const [isLoaded, setIsLoaded] = useState(!path)

  useEffect(() => {
    setIsLoaded(!path)
  }, [path])

  useEffect(() => {
    if (!path) {
      setStyle(fallbackStyle)
      setIsLoaded(true)
      return
    }
    let isCancelled = false
    const newStyle = getLoadingStyle({ path, withPreview, loadingStyle, isVideo })
    if (JSON.stringify(style) !== JSON.stringify(newStyle)) setStyle(newStyle)
    if (isVideo || !isPreload || isLoaded) return
    setIsLoaded(false)
    const image = new Image()
    const resizePath = sizeImagePath(path, size)
    image.src = resizePath
    image.onload = () => {
      if (isCancelled) return
      setStyle({ backgroundImage: `url(${resizePath})` })
      setIsLoaded(true)
    }
    image.onerror = () => {
      if (isCancelled) return
      setStyle(fallbackStyle)
      setIsLoaded(true)
    }
    return () => {
      isCancelled = true
    }
  }, [path, isPreload])

  return { backgroundStyle: style, isLoaded }
}
