/**
 * Gets the duration of the video file
 * @param {string} path - url of video file
 * @param {number} [defaultDuration] - value of duration that will be returned in case of error
 * @return {Promise<number>}
 */
export const getVideoDuration = (path, defaultDuration) => {
  return new Promise(resolve => {
    const videoElement = document.createElement('video')
    videoElement.src = path
    videoElement.onloadedmetadata = () => {
      resolve(videoElement.duration)
    }
    videoElement.onerror = () => resolve(defaultDuration) // В случае ошибки возвращаем значение по умолчанию
  })
}
